import { Grid } from '@mui/material';
import dynamic from 'next/dynamic';
import { FunctionComponent, memo } from 'react';

const ModalExpert = dynamic(() => import('../../ModalExpert'));

const Text: FunctionComponent = () => {
  return (
    <Grid
      container
      item
      direction="column"
      justifyContent="center"
      alignContent="center"
      alignItems={{ xs: 'center', lg: 'flex-start' }}
      height={{ xs: 'fit-content', lg: 581 }}
      width={{ xs: '100%', lg: '50%' }}
      xs={12}
      lg={6}
      gap={'24px'}
    >
      <Grid
        item
        className="barlow--size32"
        style={{ color: 'var(--primary-blue)' }}
      >
        Boas-vindas à sua revolução mobile
        <br />
        <b>
          <span style={{ color: 'var(--primary-green)' }}>#</span>
          ShiftYourEvolution
        </b>{' '}
        com Devyx
      </Grid>
      <Grid item className="open-sans--size18">
        Sua marca com a melhor parceria de negócios para atingir em cheio a
        atenção de seu cliente.
      </Grid>
      <Grid item>
        <ModalExpert />
      </Grid>
    </Grid>
  );
};

export default memo(Text);
